import "./styles/newanim.css"

function NewAnimation() {
    

    return (
        <div className="gradient-background">
            
        </div>
    );
  
  }
  
  export default NewAnimation;